import * as React from "react"

import SEO from "../utils/seo"
import Layout from "../components/layout"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

function IndexPage(){
  const [showButton, setShowButton] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling
    });
  };

  return (
    <>
      <SEO title="Home" image="../images/header.png"/>
      <Layout/>
      
    {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <FontAwesomeIcon icon={faChevronUp} className="up-icon"/>
        </button>
    )}
    </>
  )
}

export default IndexPage